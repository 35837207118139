import { useState, useEffect } from 'react';
import { signIn, signOut, getCurrentUser, fetchAuthSession } from 'aws-amplify/auth';
import '.././amplifyConfig';


/**
 * Custom hook for handling authentication logic.
 * 
 * @returns {Object} - An object containing authentication state and handlers.
 */
function useAuth() {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [signInAllowed, setSignInAllowed] = useState(true);
    const [errorValue, setErrorValue] = useState("");
    const [signedIn, setSignedIn] = useState(false);
    const [signingIn, setSigningIn] = useState(false);
    const [soapboxData, setSoapboxData] = useState(null);
    const [idToken, setIDToken] = useState("");
    const [institution, setInstitution] = useState("");
    const [utcTimeEpoch, setUtcTimeEpoch] = useState(0);

    useEffect(() => {
        if (username !== "" && password !== "") setSignInAllowed(false);
        else setSignInAllowed(true);
    }, [username, password]);

    /**
    * Effect to fetch the main dashboard content if the user is signed in and content is not already fetched.
    */
    useEffect(() => {
        console.log("ID Token: ", idToken.substring(0, 20) + "...");
        // CheckCurrentSession();
        if (signedIn && idToken) {
            GetContent();
        }
    }, [signedIn, idToken]);

    useEffect(() => {
        CheckCurrentSession();
    }, []);

    async function handleSignIn(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        setSigningIn(true);
        setSignInAllowed(false);
        setErrorValue("");
        const data = new FormData(event.currentTarget);
        let login = signIn({
            username: String(data.get("username")),
            password: String(data.get("password")),
            options: {
                authFlowType: 'USER_SRP_AUTH'
            }
        });
        login.then(
            async function (value) {
                const session = await fetchAuthSession();
                var idToken = String(session?.tokens?.idToken?.toString());
                setIDToken(idToken);
                console.log(idToken);
                const currentUser = await getCurrentUser();
                setUsername(currentUser.username);
                setSigningIn(false);
                setSignedIn(true);
            },
            function (error) {
                console.error(error);
                setErrorValue(error.message);
                setSigningIn(false);
                setSignInAllowed(true);
            }
        );
    };

    async function handleSignOut(event: React.FormEvent<HTMLFormElement>) {
        setSignedIn(false);
        event.preventDefault();
        setErrorValue("");
        setSoapboxData(null);
        await signOut();
        setSignedIn(false);
    };

    /**
     * Checks the current user session from Amplify Auth. 
     * Aligns the signedIn state with the current Auth session.
     */
    async function CheckCurrentSession() {
        try {
            const session = await fetchAuthSession();
            const idToken = String(session?.tokens?.idToken?.toString());
            const currentUser = await getCurrentUser();
            setIDToken(idToken);
            setSignedIn(true);
            setUsername(currentUser.username);
        } catch (error) {
            setSignedIn(false);
        }
    }

    /**
     * Fetches the education tracks content distribution from the Soapbox API.
     */
    async function GetContent() {
        const url = `https://${process.env.REACT_APP_ENV_STAGE}.soapboxapis.com/education/v3/content`;
        try {
            const response = await fetch(url, {
                headers: {
                    "Authorization": idToken
                }
            });
            if (!response.ok) {
                throw new Error(`Response status: ${response.status}`);
            }
            let data = await response.json();
            setSoapboxData(data.content);
            setInstitution(data.institution);
            setUtcTimeEpoch(data.utc);
            console.log(data);
        } catch (error) {
            console.error(error);
        }
    }

    /* 
     * Function to set the track.install and track.visibility properties using a post request to the Soapbox API.
     * gets data like
        {
            "artists": [
                {
                "id": "605705ae-43fe-478c-a8e3-3bf0e9cbbaaa",
                "name": "Ralph Johnson",
                "tracks": [
                    {
                    "id": "274f3c7d-b0ca-4c7f-9aac-f9d88312b8b3",
                    "title": "1A | Maurice White",
                    "visibility": false,
                    "install": true
                    }
                ]
                }
            ]
        }
    */
    async function setTrackInstallAndVisibility(pdata: any): Promise<any> {
        const url = `https://${process.env.REACT_APP_ENV_STAGE}.soapboxapis.com/education/v3/content`;
        const content = JSON.stringify(pdata.artists, null, 2);
        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    "Authorization": idToken,
                    "Content-Type": "application/json"
                },
                body: content
            });
            if (!response.ok) {
                throw new Error(`Response status: ${response.status}`);
            }
            let data = await response.json();
            console.log("post request url: ", url);
            console.log("post request data: ", content);
            console.log("response: ", data);
            await GetContent();
            return data;
        } catch (error) {
            console.error(error);
        }
    }










    return {
        signedIn,
        username,
        soapboxData,
        institution,
        handleSignIn,
        handleSignOut,
        signingIn,
        signInAllowed,
        errorValue,
        setTrackInstallAndVisibility,
        idToken,
        GetContent,
        utcTimeEpoch
    };
}

export default useAuth;