import React from 'react';
import { useState } from 'react';
import { ThemeProvider, CssBaseline } from '@mui/material';
import { darkTheme } from './styles/theme';
import { AuthProvider, useAuthContext } from './context/AuthContext';
import './App.css';

import DashboardTab from './components/DashboardTab';
import SupportTab from './components/SupportTab';
import LoginPage from './components/LoginPage';
import AppHeader from './components/AppHeader';
import MinimalDocumentsTab from './components/MinimalDocumentsTab';
import Footer from './components/Footer';

function AppContent() {
    const { signedIn } = useAuthContext();

    const [tab, setTab] = useState('Content management');

    const tabs = ["Content management", "Documents", "Support"];

    return (
        <>
            {!signedIn ? (
                <LoginPage />
            ) : (
                <>
                    <AppHeader tab={tab} setTab={setTab} tabs={tabs} />

                    {tab === 'Content management' && <DashboardTab />}
                    {tab === 'Documents' && <MinimalDocumentsTab />}
                    {tab === 'Support' && <SupportTab />}

                    <Footer /> {/* Add Footer */}
                </>
            )}
        </>
    );
}

function App() {
    return (
        <AuthProvider>
            <ThemeProvider theme={darkTheme}>
                <CssBaseline />
                <AppContent />
            </ThemeProvider>
        </AuthProvider>
    );
}

export default App;